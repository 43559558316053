import type { VisitFormSectionType } from '@einfachgast/shared';
import { useRoute } from 'vue-router';
import router from '@/router';
import { RouteName } from '@/router/route-name';
import { dynamicFormService } from '@/dynamic-form/dynamic-form-service';

export function useSectionNavigation () {

  const route = useRoute();
  const next = () => {
    if (dynamicFormService.getIsLastStep(route.params.step as VisitFormSectionType)) {
      router.replace({ name: RouteName.CheckingIn });
      return;
    }
    const nextStep = dynamicFormService.getNextStep(route.params.step as VisitFormSectionType);
    if (!nextStep) {
      console.log('no next step');
      return;
    }
    router.push({ name: RouteName.Checkin, params: { step: nextStep.type } });
  };

  const back = () => {
    const previousStep = dynamicFormService.getPreviousStep(route.params.step as VisitFormSectionType);
    if (!previousStep) {
      console.log('no previus step');
      return;
    }
    router.push({ name: RouteName.Checkin, params: { step: previousStep.type } });
  };

  return { next, back };
}